import React, { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

import "./CompanyInfo.css";

const reportDataOne = [
  {
    id: 1,
    title: "Name of the Company",
    address: "CIC Holding PLC",
  },
  {
    id: 2,
    title: "Company Registration No.",
    address: "PQ 88",
  },
  {
    id: 3,
    title: "Registered Office",
    address: "199, Kew Road, Colombo 2",
  },
];

const reportDataTwo = [
  {
    id: 1,
    title: "Legal Form",
    address:
      "A Public Quoted Company with limited liability incorporated in Sri Lanka in 1964. Re-registered under the Companies Act No. 07 of 2007 on 21st November 2007",
  },
  {
    id: 2,
    title: "Company Secretary",
    address: "P W Corporate Secretarial (Pvt) Ltd",
  },
];

const reportDataThree = [
  {
    id: 1,
    title: "Auditors",
    subTitle1: "KPMG Chartered Accountants",
    address1: "No. 32A, Sir Mohamed Macan Markar Mawatha, Colombo 3",
  },
  {
    id: 2,
    title: "Legal Advisers",
    subItems: [
      {
        subTitle: "Nithya Partners Attorneys-at-Law",
        address: "No. 97A, Galle Road, Colombo 3",
      },
      {
        subTitle: "Heritage Partners Attorneys-at-Law",
        address: "No. 04, Malalasekara Pedesa, Colombo 7",
      },
      {
        subTitle: "Julius & Creasy Attorney-at-Law",
        address: "No. 371, R A De Mel Mawatha, Colombo 07",
      },
    ],
  },
];

const CompanyInfo = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <center>
      <div style={{ width: "86%", marginTop: "80px", marginBottom: "80px" }}>
        <div className="report-grid" data-aos="fade-right">
          {reportDataOne.map((report) => (
            <div key={report.id} className="report-item">
              <div className="report-title">{report.title}</div>
              <div className="report-address">{report.address}</div>
            </div>
          ))}
        </div>
        <div className="report-grid-two" data-aos="fade-left">
          {reportDataTwo.map((report) => (
            <div key={report.id} className="report-item">
              <div className="report-title">{report.title}</div>
              <div className="report-address">{report.address}</div>
            </div>
          ))}
        </div>
        <div className="report-grid-three" data-aos="fade-right">
          {reportDataThree.map((report) => (
            <div key={report.id} className="report-item">
              <div className="report-title">{report.title}</div>
              {report.subItems ? (
                <div className="sub-item-grid">
                  {report.subItems.map((subItem, index) => (
                    <div key={index} className="sub-item">
                      <div className="report-address">{subItem.subTitle}</div>
                      <div className="report-subtitle">{subItem.address}</div>
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div className="report-address">{report.subTitle1}</div>
                  <div className="report-subtitle">{report.address1}</div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </center>
  );
};

export default CompanyInfo;
