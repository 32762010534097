import React from "react";
import MainBanner from "../templates/InvestorDesktop/CorporateInformation/corporateInformationMainBanner";
import CompanyInfo from "../templates/InvestorDesktop/CorporateInformation/CompanyInfo";
import Template from "../templates/InvestorDesktop/CorporateInformation/Template";


export default function CropSolutions() {
  return (
    <div>
      {/* <Header /> */}
      <MainBanner />
      <CompanyInfo />
      <Template />
      {/* <Footer /> */}
    </div>
  );
}
