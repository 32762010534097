import React from "react";
import "./corporateInformationMainBanner.css";
import Image from "../../../images/CorporateInformation.png";

export default function corporateInformationMainBanner() {
  return (
    <div>
      <div style={{ position: "relative", marginBottom: "-4px" }}>
        <img className="analystCoverage-home-image" alt="" src={Image} />
        <div className="analystCoverage-image-text-box">
          <div className="analystCoverage-image-background-text">
            <h1 className="analystCoverage-image-topic">
              Corporate Information
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
