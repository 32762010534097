import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Template.css";

const Layout = () => {
  const [activeTab, setActiveTab] = useState("Directors");

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    AOS.refresh();
  }, [activeTab]);

  const data = {
    Directors: [
      "S.H. Amarasakera (Chairman)",
      "R.S. Captain",
      "S.M. Enderby",
      "J.R. Gunaratne",
      "M.P. Jayawardena",
      "D.T.S.H. Mudalige",
      "P.R. Saldin",
      "K.D Senewiratne",
    ],
    "Audit Committee": [
      "P. R. Saldin - Committee Chairman, Non-Independent, Non-Executive Director",
      "S. M. Enderby - Independent, Non-Executive Director",
      "D. T. S. H. Mudalige - Independent, Non-Executive Director",
      "M. P. Jayawardena - Independent, Non-Executive Director",
    ],
    "Human Capital & Compensation Committee": [
      "P. R. Saldin - Committee Chairman, Non-Executive Director",
      "S. H. Amarasekera - Independent, Non-Executive Director/Chairman",
      "R. S. Captain - Non-Executive Director",
      "S. M. Enderby - Independent Non-Executive Director",
      "M. P. Jayawardena - Independent Non-Executive Director",
    ],
    "Nominations Committee": [
      "S.H. Amarasekera - Independent, Non-Executive Director/Committee Chairman",
      "R.S. Captain - Non-Independent, Non-Executive Director",
      "P. R. Saldin - Non-Independent, Non-Executive Director",
    ],
    Bankers: ["Banker X", "Banker Y"],
    "Related Party Transactions Review committee": [
      "M. P. Jayawardena - Committee Chairman, Independent, Non-Executive Director",
      "S. M. Enderby - Independent, Non-Executive Director",
      "J. R. Gunaratne - Independent, Non-Executive Director",
    ],
  };

  return (
    <center>
      <div style={{ width: "90%", marginBottom: "20px" }}>
        <div className="container">
          <div className="sidebar">
            {Object.keys(data).map((item) => (
              <button
                key={item}
                className={`sidebar-button ${
                  activeTab === item ? "active" : ""
                }`}
                onClick={() => setActiveTab(item)}
              >
                {item}
              </button>
            ))}
          </div>
          <div className="content">
            <h2>{activeTab}</h2>
            <ul key={activeTab}>
              {data[activeTab]?.map((member, index) => (
                <li
                  key={index}
                  data-aos="fade-up"
                  data-aos-delay={index * 100}
                >
                  {member}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </center>
  );
};

export default Layout;
